import React from "react";
import BlogListItem from "./BlogListItem";

const BlogList = ({data}) => {
    return  (
        <section className="uk-section">
            <div className="uk-container uk-container-small">
                <div className="uk-grid" data-uk-grid>
                    <div className="post-list uk-width-1-1">
                        {data.slice(1).map(post =>
                            <BlogListItem blog={post.node} key={post.node.slug} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogList;
