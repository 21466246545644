import React from 'react';
import { Link } from "gatsby";
import Img from 'gatsby-image';
import * as Markdown from 'react-markdown';
import style from "./News.module.css";
import eye from "../../images/icons/Eye.png";

const BlogListItem = props => {
    const { title, copy, image, publishDate, slug, topic } = props.blog;
    return (
        <div className="uk-card uk-card-large uk-margin-large-bottom">
            {image &&
            <div className="uk-card-media-top uk-transition-toggle uk-position-relative">
                <Img
                    fluid={image.fluid}
                    alt={image.title}
                    className={`${style.blogMainImage} uk-border-rounded`}
                    objectFit="cover"
                    objectPosition="50% 50%"
                     />
                <div className={`${style.overlayBackground} uk-transition-fade uk-position-cover uk-overlay uk-flex uk-flex-center uk-flex-middle`}>
                    <Link
                        style={{textDecoration: 'none'}}
                        to={`/blog/${slug}`}>
                    <span>
                        <img
                            src={eye}
                            alt={image.title}
                            style={{maxWidth: "72px"}}
                            />
                    </span>
                    </Link>
                </div>
            </div>
            }
            <div className="uk-card-body uk-padding-small-top">
                <h3 className="uk-text-bold uk-margin-small-bottom">
                    <Link className="uk-link-text"
                        to={`/blog/${slug}`}>
                        {title}
                    </Link>
                </h3>

                <div>
                    <Markdown
                        source={copy.childMarkdownRemark.html}
                        escapeHtml={false}
                    />
                </div>

                <Link style={{color: "#202020"}} className="uk-button uk-button-text cta-button"
                    to={`/blog/${slug}`}>
                Read this
                </Link>
            </div>
            <hr className="uk-hr" />
        </div>
    );
};

export default BlogListItem
