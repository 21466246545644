import React from "react"
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout"
import Header from "../components/Header"
import BlogList from '../components/News/BlogList';
import SEO from "../components/seo"
import style from "../components/News/News.module.css";

const Blog = ({data, ...props}) => {
    let blogData = data.allContentfulBlogPost.edges;
    blogData.sort((a, b) => new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime());

    return (
        <Layout>
            <SEO title="Articles" />
            <Helmet>
            </Helmet>
            <Header data={blogData[0].node} link={`/blog/${blogData[0].node.slug}`} />
            <section className="uk-section">
                <div style={{minHeight: "100vh"}}>
                    <div className={`${style.postList}`}><BlogList data={blogData} /></div>
                </div>
            </section>

        </Layout>
    )
}

export default Blog;

export const blogPageQuery = graphql`
    query blogPageQuery {
      allContentfulBlogPost {
        edges {
          node {
            title
            publishDate
            copy {
              childMarkdownRemark {
                html
              }
            }
            metaKeywords
            image {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            slug
          }
        }
      }
    }`
